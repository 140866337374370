<template>
  <div class="inline-flex flex-wrap justify-center text-xs" v-if="rit">
    <label class="label-info ml-0 text-xs">
      <i class="fas fa-users text-base"></i> {{ rit[`personen${suffix}`] || '?' }}
    </label>
    <label v-if="rit.is_bus || rit[`personen${suffix}`] > 4" class="label-info text-xs">
      <i class="fas fa-shuttle-van text-base"></i>
    </label>
    <label v-if="!hideKm" class="label-base text-xs" :class="{
      'label-info': rit[`kilometers${suffix}`] < 75,
      'bg-orange-300 text-black':
        rit[`kilometers${suffix}`] >= 75 && rit[`kilometers${suffix}`] < 150,
      'bg-red-500 text-white': rit[`kilometers${suffix}`] >= 150,
    }">
      <i class="fas fa-car text-base"></i> {{ rit[`kilometers${suffix}`] || '?' }} km
    </label>
    <label v-if="rit.is_betaalterminal && !store.getters.hasOnderaannemerPermission" class="label-info text-xs">
      <i class="far fa-credit-card text-base"></i>
    </label>
    <label v-if="rit[`maxicosis${suffix}`] > 0" class="label-info text-xs">
      <i class="fas fa-baby-carriage text-base"></i>(M) {{ rit[`maxicosis${suffix}`] }}
    </label>
    <label v-if="rit[`kinderstoelen${suffix}`] > 0" class="label-info text-xs">
      <i class="fas fa-baby text-base"></i>(S) {{ rit[`kinderstoelen${suffix}`] }}
    </label>
    <label v-if="rit[`kinderzitjes${suffix}`] > 0" class="label-info text-xs">
      <i class="fas fa-child text-base"></i>(Z) {{ rit[`kinderzitjes${suffix}`] }}
    </label>
    <label v-if="rit[`handbagage${suffix}`] > 0 && rit[`bagage${suffix}`] <= 0" class="label-info text-xs">
      Enkel&nbsp;<i class="fas fa-toolbox text-base"></i> ({{rit[`handbagage${suffix}`]}}x)
    </label>
    <template v-else>
      <label v-if="rit[`handbagage${suffix}`] > 0" class="label-info text-xs">
        {{rit[`handbagage${suffix}`]}}x <i class="fas fa-toolbox text-base"></i>
      </label>
      <label v-if="rit[`bagage${suffix}`] > 0" class="label-info text-xs">
        {{rit[`bagage${suffix}`]}}x <i class="fas fa-suitcase text-base"></i>
      </label>
    </template>
    <label v-if="rit.kost_wakeup_call > 0" class="label-base bg-purple-500 text-white text-xs">
      Wake-up call
    </label>
    <label v-if="rit.kost_prive_vervoer > 0" class="label-base bg-yellow-400 text-white text-xs">
      Privé
    </label>
    <label class="label-base bg-orange-500 text-white text-xs" v-if="verschillend && rit.is_retour" @click="verschillendAlert">
      <i class="fas fa-exclamation-triangle text-xs"></i>
    </label>
    <label class="label-base bg-orange-500 text-white text-xs" v-if="rit.kost_last_minute > 0" @click="verschillendAlert">
      Last minute
    </label>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useStore } from 'vuex'

defineProps({
  rit: {
    type: Object,
    required: true,
  },
  suffix: {
    type: String,
    default: '',
  },
  hideKm: Boolean,
  verschillend: Boolean,
})

const store = useStore()

const verschillendAlert = () => {
  alert('Er is een verschil gevonden tussen heen- en terugreis in één of verschillende aantallen personen, bagage, handbagage, kinderstoelen, kinderzitjes of maxicosis.')
}
</script>
